const APP_CONST = {

    icone:{
        entregar: {
            i_classname: "fa-solid fa-truck-ramp-box"
        },
        salvar: {
            i_classname: "fa-regular fa-floppy-disk"
        },
        voltar: {
            i_classname: "fa-solid fa-door-open"
        }
    }

}

export default APP_CONST;